import { useForm } from "react-hook-form";
import { ICreateMarathon } from "../types";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { createMarathon } from "../../../entities/admin/model/thunk/createMarathon";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { EditorState } from "draft-js";
import { fillFormData } from "./converterToFormData";

const validFileExtensions = {
  image: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
  file: ["pdf", "jpg", "docx", "doc", "ppt", "pptx", "rtf", "wpd", "png"],
};
function isValidFileType(fileName: string, fileType: "image" | "file") {
  return (
    fileName &&
    // @ts-ignore
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  );
}
const MAX_FILE_SIZE = 1024000;

export const useCreateMarathonForm = (setCreatedMarathonModalOpen: any) => {
  const dispatch = useDispatch();
  const form = useForm<ICreateMarathon>({
    defaultValues: {
      documents: [],
      translations: [{ languageId: 1 }, { languageId: 2 }, { languageId: 3 }],
      partners: [
        {
          serialNumber: 1,
          id: uuidv4(),
          translations: [
            {
              name: "",
              languageId: 1,
            },
            {
              name: "",
              languageId: 2,
            },
            {
              name: "",
              languageId: 3,
            },
          ],
          partnerCompanies: [],
        },
      ],
      publicOffer: [{ languageId: 1 }, { languageId: 2 }, { languageId: 3 }],
      distances: [
        {
          name: "",
          distanceAges: [{ gender: false }, { gender: true }],
        },
      ],
    },
    resolver: yupResolver(
      yup.object().shape({
        dateEvent: yup
          .date()
          .typeError("Введите корректную дату")
          .min(new Date(), "Дата проведения не может быть в прошлом")
          .required("Укажите дату мероприятия"),
        startDateAcceptingApplications: yup
          .date()
          .typeError("Введите корректную дату")
          .max(
            yup.ref("dateEvent"),
            "Дата начала приема заявок не может быть позже даты мероприятия"
          )
          .required("Укажите дату начала приема заявок"),
        endDateAcceptingApplications: yup
          .date()
          .typeError("Введите корректную дату")
          .max(
            yup.ref("dateEvent"),
            "Дата начала приема заявок не может быть после даты мероприятия"
          )
          .min(
            yup.ref("startDateAcceptingApplications"),
            "Дата конца приема заявок должна быть позже даты начала"
          )
          .required("Укажите дату конца приема заявок"),
        translations: yup.array().of(
          yup.object({
            logo: yup
              .mixed()
              .required("Логотип обязателен")
              .test(
                "required",
                "Неверный тип файла логотипа",
                (value) => !!value?.[0]?.name
              )
              .test(
                "is-valid-type",
                "Неверный тип файла логотипа",
                (value) => !!isValidFileType(value?.[0]?.name || "", "image")
              )
              .test(
                "is-valid-size",
                "Максимальный размер файла логотипа - 100 КБ",
                (value) => value?.[0] && value?.[0]?.size <= MAX_FILE_SIZE
              ),
            name: yup.string().required("Укажите название"),
            place: yup.string().required("Укажите место проведения"),
            startKitMessage: yup
              .string()
              .required("Укажите состав стартового пакета"),
            text: yup
              .mixed()
              .required("Укажите описание")
              .test(
                "is-valid-text",
                "Укажите описание",
                (value: EditorState) => {
                  return !!value?.getCurrentContent()?.getPlainText();
                }
              ),
          })
        ),
        partners: yup.array().of(
          yup.object({
            translations: yup.array().of(
              yup.object({
                name: yup.string().required("Укажите заголовок"),
              })
            ),
            partnerCompanies: yup
              .array()
              .of(
                yup.object({
                  logo: yup
                    .mixed()
                    .required("Логотип компании-партнера обязателен")
                    .test(
                      "required",
                      "Неверный тип файла логотипа компании-партнера",
                      (value) => !!value?.[0]?.name
                    )
                    .test(
                      "is-valid-type",
                      "Неверный тип файла логотипа компании-партнера",
                      (value) =>
                        !!isValidFileType(value?.[0]?.name || "", "image")
                    )
                    .test(
                      "is-valid-size",
                      "Максимальный размер файла логотипа компании-партнера - 100 КБ",
                      (value) => value?.[0] && value?.[0]?.size <= MAX_FILE_SIZE
                    ),
                  name: yup.string().required("Укажите Название"),
                  url: yup.string(),
                })
              )
              .required("Требуется хотя бы одна компания-партнер")
              .min(1, "Требуется хотя бы одна компания-партнер"),
          })
        ),
        publicOffer: yup.array().of(
          yup.object({
            file: yup
              .mixed()
              .required("Требуется файл публичной оферты") // Required file
              .test(
                "required",
                "Неверный тип файла публичной оферты",
                (value) => {
                  // console.log(value);
                  return !!value?.[0]?.name;
                }
              )
              .test(
                "is-valid-type",
                "Неверный тип файла публичной оферты",
                (value) => !!isValidFileType(value?.[0]?.name || "", "file") // Not a valid image type
              )
              .test(
                "is-valid-size",
                "Максимальный размер файла публичной оферты - 100 КБ", // Max allowed size is 100KB
                (value) => value?.[0] && value?.[0]?.size <= MAX_FILE_SIZE
              ),
          })
        ),
        documents: yup
          .array()
          .min(1, "Необходимо выбрать файлы") // required
          .required("Необходимо выбрать файлы"), // Required
        insurancePrice: yup
          .number()
          .typeError("Укажите стоимость страховки")
          .min(1, "Минимальная стоимость страховки: 1")
          .required("Укажите стоимость страховки"),
        distances: yup.array().of(
          yup
            .object()
            .shape({
              hasInsurance: yup
                .bool()
                .required("Наличие страховки обязательно"),
              name: yup.string().required("Укажите название дистанции"),
              startNumbersFrom: yup
                .number()
                .typeError("Укажите число")
                .min(1, "Начальный номер не может быть равен 0")
                .required("Укажите число"),
              startNumbersTo: yup
                .number()
                .typeError("Укажите число")
                .min(0, "Начальный номер не может быть равен 0")
                .required("Укажите число")
                .test(
                  "startNumbersCheck",
                  "Конечный номер не должен быть меньше начального",
                  function (value) {
                    const startNumbersFrom = this.parent.startNumbersFrom;
                    // @ts-ignore
                    return value >= startNumbersFrom;
                  }
                ),
              distancePrices: yup
                .array()
                .of(
                  yup.object({
                    dateStart: yup
                      .date()
                      .typeError("Введите корректную дату")
                      .required("Укажите дату начала"), // Date required
                    dateEnd: yup
                      .date()
                      .typeError("Введите корректную дату")
                      .required("Укажите дату окончания"), // Date required
                    price: yup
                      .number()
                      .min(0)
                      .typeError("Укажите цену периода")
                      .required("Укажите цену периода"),
                  })
                )
                .test("pricesError", "price error", function (value, context) {
                  const { distancePrices } = this.parent;
                  // console.log(this.resolve("startDateAcceptingApplications"));
                  // console.log(
                  //   distancePrices?.[distancePrices?.length - 1]?.dateEnd,
                  //   yup.ref("startDateAcceptingApplications")
                  // );

                  return true;
                }),
              distanceAges: yup.array().of(
                yup.object({
                  ageFrom: yup
                    .number()
                    .typeError("Укажите возраст")
                    .min(0, "Минимальный возраст: 0")
                    .max(90, "Максимальный возраст: 90")
                    .required("Укажите возраст"),
                  ageTo: yup
                    .number()
                    .typeError("Укажите возраст")
                    .min(0, "Минимальный возраст: 0")
                    .max(90, "Максимальный возраст: 90")
                    .required("Укажите возраст")
                    .test(
                      "startNumbersCheck",
                      "Возраст не должен быть меньше начального",
                      function (value) {
                        const ageFrom = this.parent.ageFrom;
                        // @ts-ignore
                        return value >= ageFrom;
                      }
                    ),
                })
              ),
            })
            .test(
              "no-overlap",
              "Дистанции не должны пересекаться",
              function (value) {
                const distances = this.parent.distances;
                const intervals = [];
                for (let i = 0; i < distances?.length; i++) {
                  const { startNumbersFrom, startNumbersTo } = distances[i];
                  for (let j = startNumbersFrom; j < startNumbersTo; j++) {
                    if (intervals[j]) {
                      return false;
                    }
                    intervals[j] = true;
                  }
                }
                return true;
              }
            )
        ),
      })
    ),
    mode: "all",
    shouldFocusError: true,
    reValidateMode: "onChange",
    criteriaMode: "all",
  });

  const handlecreateMarathon = async (data: ICreateMarathon) => {
    console.log("marathon", data);
    const createdMarathonResponse = await dispatch(
      // @ts-ignore
      createMarathon(fillFormData(data))
    );
    if (createdMarathonResponse?.meta?.requestStatus === "fulfilled") {
      setCreatedMarathonModalOpen(true);
    }
  };
  return {
    handlecreateMarathon,
    form,
  };
};
